const mobileScreen = 719;

const states = [
    { state: "Brak", value: "" },
    { state: "Dolnośląskie", value: "Dolnośląskie" },
    { state: "Kujawsko-Pomorskie", value: "Kujawsko-Pomorskie" },
    { state: "Lubelskie", value: "Lubelskie" },
    { state: "Lubuskie", value: "Lubuskie" },
    { state: "Łódzkie", value: "Łódzkie" },
    { state: "Małopolskie", value: "Małopolskie" },
    { state: "Mazowieckie", value: "Mazowieckie" },
    { state: "Opolskie", value: "Opolskie" },
    { state: "Podkarpackie", value: "Podkarpackie" },
    { state: "Podlaskie", value: "Podlaskie" },
    { state: "Pomorskie", value: "Pomorskie" },
    { state: "Śląskie", value: "Śląskie" },
    { state: "Świętokrzyskie", value: "Świętokrzyskie" },
    { state: "Warmińsko-Mazurskie", value: "Warmińsko-Mazurskie" },
    { state: "Wielkopolskie", value: "Wielkopolskie" },
    { state: "Zachodniopomorskie", value: "Zachodniopomorskie" }
];

export const requirementsLabels = {
    password: "Minimum 8 znaków, co najmniej 1 wielka litera, co najmniej 1 cyfra.",

}


const backendUrl = "https://api.zdaj-to.pl";
// const backendUrl = "http://localhost:3001";

export {mobileScreen, states, backendUrl};